import api from '../../api/user/user'
import router from '../../router'
import store from '../../store'
import { app } from "@/main";

// initial state
const state = () => ({
  current_user: null,
  login_infos: null,
  price_display_inctax: null,
  user: null,
})

// getters
const getters = {
}

// actions
const actions = {
  getCurrentUser({ commit }) {
    api.getCurrentUser(user => {
      commit('setCurrentUser', user)
      commit('setPriceDisplayInctax', user.price_display_inctax)
    })
  },
  getLoginInfos({ commit }) {
    api.getLoginInfos(infos => {
      commit('setLoginInfos', infos)
      if (infos.is_logged) {
        router.push({ name: "dashboard" });
      }
    })
  },
  getUser({ commit }, user) {
    return api.getItem(user.id, infos => {
      commit('setTitle', infos.title, { root: true })
      commit('setIcon', infos.icon, { root: true })
      commit('setBreadcrumbs', infos.breadcrumbs, { root: true })
      commit('setUser', infos.user)
    })
  },
  //
  async login() {
    localStorage.setItem("auth", "true");
    store.dispatch("user/getCurrentUser");
    store.dispatch("layout/refresh");
  },
  logout({ commit }) {
    api.logout(data => {
      localStorage.removeItem("auth");
      commit('reset', null)
      commit('layout/reset', null, { root: true })
      commit('setBreadcrumbs', null, { root: true })
      if (data.redirect) {
        window.location.replace(data.redirect);
      } else {
        router.push({ name: "login" });
        document.body.classList.remove("sidebar-lg-show");
      }
    })
  },
  reinitLayout({ commit }) {
    localStorage.removeItem("auth");
    commit('reset', null)
    commit('layout/reset', null, { root: true })
    commit('setBreadcrumbs', null, { root: true })
    document.body.classList.remove("sidebar-lg-show");
  },
}

// mutations
const mutations = {
  setCurrentUser(state, user) {
    // if (user) {
    //   // push notifications
    //   // for a specific User
    //   window.Echo.private(user.tenant + ".notification." + user.id).listen(
    //     ".notification.new",
    //     (e) => {
    //       // check tenant and user
    //       if (e.tenant == user.tenant && e.notifiable_user_id == user.id) {
    //         displayNotification(e)
    //       }
    //     }
    //   );

    //   // for all Users of the Tenant
    //   window.Echo.private(user.tenant + ".notification").listen(
    //     ".notification.new",
    //     (e) => {
    //       // check tenant
    //       if (e.tenant == user.tenant) {
    //         displayNotification(e)
    //       }
    //     }
    //   );

    //   // for all Users of all Tenants
    //   window.Echo.private("notification_for_everyone").listen(
    //     ".notification.new",
    //     (e) => {
    //       displayNotification(e)
    //     }
    //   );
    // } else if (state.current_user) {
    //   window.Echo.leave(state.current_user.tenant + ".notification." + state.current_user.id)
    //   window.Echo.leave(state.current_user.tenant + ".notification")
    //   window.Echo.leave("notification_for_everyone")
    // }

    state.current_user = user;
  },
  setLoginInfos(state, login_infos) {
    state.login_infos = login_infos
  },
  setPriceDisplayInctax(state, price_display_inctax) {
    state.price_display_inctax = price_display_inctax
  },
  setUser(state, user) {
    state.user = user
  },
}
mutations.reset = (state) => {
  mutations.setCurrentUser(state, null);
};

function displayNotification(e) {
  if (!("Notification" in window)) {
    // The browser does not support notifications, create in app notification
    sendNotification(e.message, 'app', e.type)
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    sendNotification(e.message, 'desktop')
  } else if (Notification.permission !== "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // If the user accepts, create a notification
        sendNotification(e.message, 'desktop')
      } else {
        // If the user denies, create in app notification
        sendNotification(e.message, 'app', e.type)
      }
    });
  } else {
    // If the user has denied notifications, create in app notification
    sendNotification(e.message, 'app', e.type)
  }
}

function sendNotification(message, type, variant = 'info') {
  if (type === 'desktop') {
    const notification = new Notification("Boomerang Web", {
      body: message,
      icon: "./favicon.png",
    });
  } else {
    app.$bvToast.toast(message, {
      variant: variant,
      noAutoHide: true,
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}