import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

let prefix = '/bwapp';
let central_prefix = prefix + '/central'
const DEFAULT_TITLE = 'Boomerang Web';

const routes = [
  { path: '/', redirect: prefix + '/' },
  {
    name: 'allocation1s',
    path: prefix + '/allocation1s',
    meta: {
      // title: DEFAULT_TITLE + ' | Allocation1s',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation1" */'../views/budget/allocation1/List.vue'),
  },
  {
    name: 'allocation1_create',
    path: prefix + '/allocation1s/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Allocation1',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation1" */'../views/budget/allocation1/Show.vue'),
  },
  {
    name: 'allocation1',
    path: prefix + '/allocation1s/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Allocation1',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation1" */'../views/budget/allocation1/Show.vue'),
  },
  {
    name: 'allocation2s',
    path: prefix + '/allocation2s',
    meta: {
      // title: DEFAULT_TITLE + ' | Allocation2s',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation2" */'../views/budget/allocation2/List.vue'),
  },
  {
    name: 'allocation2_create',
    path: prefix + '/allocation2s/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Allocation2',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation2" */'../views/budget/allocation2/Show.vue'),
  },
  {
    name: 'allocation2',
    path: prefix + '/allocation2s/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Allocation2',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation2" */'../views/budget/allocation2/Show.vue'),
  },
  {
    name: 'allocation3s',
    path: prefix + '/allocation3s',
    meta: {
      // title: DEFAULT_TITLE + ' | Allocation3s',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation3" */'../views/budget/allocation3/List.vue'),
  },
  {
    name: 'allocation3_create',
    path: prefix + '/allocation3s/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Allocation3',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation3" */'../views/budget/allocation3/Show.vue'),
  },
  {
    name: 'allocation3',
    path: prefix + '/allocation3s/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Allocation3',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation3" */'../views/budget/allocation3/Show.vue'),
  },
  {
    name: 'allocation4s',
    path: prefix + '/allocation4s',
    meta: {
      // title: DEFAULT_TITLE + ' | Allocation4s',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation4" */'../views/budget/allocation4/List.vue'),
  },
  {
    name: 'allocation4_create',
    path: prefix + '/allocation4s/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Allocation4',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation4" */'../views/budget/allocation4/Show.vue'),
  },
  {
    name: 'allocation4',
    path: prefix + '/allocation4s/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Allocation4',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "allocation4" */'../views/budget/allocation4/Show.vue'),
  },
  {
    name: 'accounting_entries',
    path: prefix + '/accounting-entries',
    meta: {
      // title: DEFAULT_TITLE + ' | Accounting Entries',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_entry" */'../views/budget/accounting_entry/List.vue'),
  },
  {
    name: 'accounting_entry_create',
    path: prefix + '/accounting-entries/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Accounting Entry',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_entry" */'../views/budget/accounting_entry/Show.vue'),
  },
  {
    name: 'accounting_entry',
    path: prefix + '/accounting-entries/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Accounting Entry',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_entry" */'../views/budget/accounting_entry/Show.vue'),
  },
  {
    name: 'accounting_exports',
    path: prefix + '/accounting-exports',
    meta: {
      // title: DEFAULT_TITLE + ' | Accounting Exports',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_export" */'../views/budget/accounting_export/List.vue'),
  },
  {
    name: 'accounting_export_create',
    path: prefix + '/accounting-exports/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Accounting Export',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_export" */'../views/budget/accounting_export/Show.vue'),
  },
  {
    name: 'accounting_export',
    path: prefix + '/accounting-exports/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Accounting Export',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_export" */'../views/budget/accounting_export/Show.vue'),
  },
  {
    name: 'accounting_vat_entries',
    path: prefix + '/accounting-vat-entries',
    meta: {
      // title: DEFAULT_TITLE + ' | Accounting Vat Entries',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_vat_entry" */'../views/budget/accounting_vat_entry/List.vue'),
  },
  {
    name: 'accounting_vat_entry_create',
    path: prefix + '/accounting-vat-entries/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Accounting Vat Entry',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_vat_entry" */'../views/budget/accounting_vat_entry/Show.vue'),
  },
  {
    name: 'accounting_vat_entry',
    path: prefix + '/accounting-vat-entries/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Accounting Vat Entry',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "accounting_vat_entry" */'../views/budget/accounting_vat_entry/Show.vue'),
  },
  {
    name: 'articles',
    path: prefix + '/articles',
    meta: {
      // title: DEFAULT_TITLE + ' | Articles',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "article" */'../views/catalog/article/List.vue'),
    children: [
      {
        name: 'article_create',
        path: prefix + '/articles/create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Article',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "article" */'../views/catalog/article/Show.vue'),
      },
      {
        name: 'article',
        path: prefix + '/articles/:id',
        meta: {
          // title: DEFAULT_TITLE + ' | Show Article',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "article" */'../views/catalog/article/Show.vue'),
      },
    ]
  },
  {
    name: 'companies',
    path: prefix + '/companies',
    meta: {
      // title: DEFAULT_TITLE + ' | Companies',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "company" */'../views/budget/company/List.vue'),
  },
  {
    name: 'company_create',
    path: prefix + '/companies/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Company',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "company" */'../views/budget/company/Show.vue'),
  },
  {
    name: 'company',
    path: prefix + '/companies/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Company',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "company" */'../views/budget/company/Show.vue'),
  },
  {
    name: 'currencies',
    path: prefix + '/currencies',
    meta: {
      // title: DEFAULT_TITLE + ' | Currencies',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "currency" */'../views/finance/currency/List.vue'),
    children: [
      {
        name: 'currency_rates',
        path: prefix + '/currencies/update-rates',
        meta: {
          // title: DEFAULT_TITLE + ' | Update Currency Rates',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "currency" */'../components/finance/currency/AutoUpdateRates.vue'),
      },
    ]
  },
  {
    name: 'currency_create',
    path: prefix + '/currencies/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Currency',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "currency" */'../views/finance/currency/Show.vue'),
  },
  {
    name: 'currency',
    path: prefix + '/currencies/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Currency',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "currency" */'../views/finance/currency/Show.vue'),
  },
  {
    name: 'dashboard',
    path: prefix + '/',
    meta: {
      // title: DEFAULT_TITLE + ' | Dashboard',
      // breadCrumb: 'Accueil',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */'../views/dashboard/Index.vue'),
  },
  {
    name: 'data_imports',
    path: prefix + '/data-import',
    meta: {
      // title: DEFAULT_TITLE + ' | Data Imports',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "data_import" */'../views/data_import/Index.vue'),
  },
  {
    name: 'data_import',
    path: prefix + '/data-import/:data_type/:detail?',
    meta: {
      // title: DEFAULT_TITLE + ' | Data Import',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "data_import" */'../views/data_import/Form.vue'),
  },
  {
    name: 'delegations',
    path: prefix + '/delegations',
    meta: {
      // title: DEFAULT_TITLE + ' | Delegations',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "delegation" */'../views/user/delegation/List.vue'),
  },
  {
    name: 'delegation_create',
    path: prefix + '/delegations/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Delegation',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "delegation" */'../views/user/delegation/Show.vue'),
  },
  {
    name: 'delegation',
    path: prefix + '/delegations/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Delegation',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "delegation" */'../views/user/delegation/Show.vue'),
  },
  {
    name: 'deliveries',
    path: prefix + '/deliveries',
    meta: {
      // title: DEFAULT_TITLE + ' | Deliveries',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "delivery" */'../views/delivery/List.vue'),
    children: [
      {
        name: 'delivery_create',
        path: prefix + '/deliveries/create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Delivery',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "delivery" */'../views/delivery/Create.vue'),
      },
    ]
  },
  {
    name: 'delivery',
    path: prefix + '/deliveries/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Delivery',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "delivery" */'../views/delivery/Show.vue'),
  },
  {
    name: 'delivery_addresses',
    path: prefix + '/delivery-addresses',
    meta: {
      // title: DEFAULT_TITLE + ' | Delivery Addresses',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "delivery_address" */'../views/delivery/delivery_address/List.vue'),
    children: [
      {
        name: 'delivery_address_create',
        path: 'create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Delivery Address',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "delivery_address" */'../components/delivery/delivery_address/Form.vue'),
      },
      {
        name: 'delivery_address',
        path: ':id',
        meta: {
          // title: DEFAULT_TITLE + ' | Show Delivery Address',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "delivery_address" */'../components/delivery/delivery_address/Form.vue'),
      },
    ],
  },
  {
    name: 'departments',
    path: prefix + '/departments',
    meta: {
      // title: DEFAULT_TITLE + ' | Departments',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "department" */'../views/budget/department/List.vue'),
  },
  {
    name: 'department_create',
    path: prefix + '/departments/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Department',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "department" */'../views/budget/department/Show.vue'),
  },
  {
    name: 'department',
    path: prefix + '/departments/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Department',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "department" */'../views/budget/department/Show.vue'),
  },
  {
    name: 'external_charges',
    path: prefix + '/external-charges',
    meta: {
      // title: DEFAULT_TITLE + ' | External charges',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "external_charge" */'../views/external_charge/List.vue'),
    children: [
      {
        name: 'external_charge_create',
        path: 'create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create External charge', 
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "external_charge" */'../views/external_charge/Create.vue'),
      },
    ],
  },
  {
    name: 'external_charge',
    path: prefix + '/external-charges/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show External charge',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "external_charge" */'../views/external_charge/Show.vue'),
  },
  {
    name: 'failed_jobs',
    path: prefix + '/failed-jobs',
    meta: {
      // title: DEFAULT_TITLE + ' | Failed Jobs',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/failed_job/List.vue'),
  },
  {
    name: 'features',
    path: prefix + '/features',
    meta: {
      // title: DEFAULT_TITLE + ' | Features',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "feature" */'../views/catalog/feature/List.vue'),
  },
  {
    name: 'feature_create',
    path: prefix + '/features/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Feature',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "feature" */'../views/catalog/feature/Show.vue'),
  },
  {
    name: 'feature',
    path: prefix + '/features/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Feature',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "feature" */'../views/catalog/feature/Show.vue'),
  },
  {
    name: 'fiscal_periods',
    path: prefix + '/fiscal-periods',
    meta: {
      // title: DEFAULT_TITLE + ' | Fiscal Periods',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "fiscal_period" */'../views/budget/fiscal_period/List.vue'),
    children: [
      {
        name: 'fiscal_period_wizard',
        path: prefix + '/fiscal-periods/wizard',
        meta: {
          // title: DEFAULT_TITLE + ' | Fiscal Period Wizard',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "fiscal_period" */'../views/budget/fiscal_period/Wizad.vue'),
      },
    ],
  },
  {
    name: 'fiscal_period_create',
    path: prefix + '/fiscal-periods/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Fiscal Period',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "fiscal_period" */'../views/budget/fiscal_period/Show.vue'),
  },
  {
    name: 'fiscal_period',
    path: prefix + '/fiscal-periods/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Fiscal Period',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "fiscal_period" */'../views/budget/fiscal_period/Show.vue'),
  },
  {
    name: 'geographic_sectors',
    path: prefix + '/geographic-sectors',
    meta: {
      // title: DEFAULT_TITLE + ' | Geographic Sectors',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "geographic_sector" */'../views/geographic_sector/List.vue'),
  },
  {
    name: 'geographic_sector_create',
    path: prefix + '/geographic-sectors/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Geographic Sector',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "geographic_sector" */'../views/geographic_sector/Show.vue'),
  },
  {
    name: 'geographic_sector',
    path: prefix + '/geographic-sectors/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Geographic Sector',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "geographic_sector" */'../views/geographic_sector/Show.vue'),
  },
  {
    name: 'incoterms',
    path: prefix + '/incoterms',
    meta: {
      // title: DEFAULT_TITLE + ' | Incoterms',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "incoterm" */'../views/finance/incoterm/List.vue'),
  },
  {
    name: 'incoterm_create',
    path: prefix + '/incoterms/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Incoterm',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "incoterm" */'../views/finance/incoterm/Show.vue'),
  },
  {
    name: 'incoterm',
    path: prefix + '/incoterms/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Incoterm',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "incoterm" */'../views/finance/incoterm/Show.vue'),
  },
  {
    name: 'jobs',
    path: prefix + '/jobs',
    meta: {
      // title: DEFAULT_TITLE + ' | Jobs',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/job/List.vue'),
  },
  {
    name: 'login',
    path: prefix + '/login',
    meta: {
      guest_only: true,
    },
    component: () => import(/* webpackChunkName: "auth" */'../views/auth/Login.vue')
  },
  {
    name: 'login_from_token',
    path: prefix + '/login-from-token/:login_token',
    meta: {
      guest_only: true,
    },
    component: () => import(/* webpackChunkName: "auth" */'../views/auth/LoginFromToken.vue')
  },
  {
    name: 'login_supplier',
    path: prefix + '/supplier-login/:login_token',
    meta: {
      guest_only: true,
    },
    component: () => import(/* webpackChunkName: "auth" */'../views/auth/SupplierLoginRequest.vue')
  },
  {
    name: 'messages',
    path: prefix + '/messages',
    meta: {
      // title: DEFAULT_TITLE + ' | Messages',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "message" */'../views/message/List.vue'),
  },
  {
    name: 'message_create',
    path: prefix + '/messages/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Message',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "message" */'../views/message/Show.vue'),
  },
  {
    name: 'message',
    path: prefix + '/messages/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Message',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "message" */'../views/message/Show.vue'),
  },
  {
    name: 'needs',
    path: prefix + '/needs',
    meta: {
      // title: DEFAULT_TITLE + ' | Needs',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "need" */'../views/need/List.vue'),
    children: [
      {
        name: 'need_create',
        path: prefix + '/needs/create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Need',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "need" */'../views/need/Create.vue'),
      },
    ]
  },
  {
    name: 'need',
    path: prefix + '/needs/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Need',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "need" */'../views/need/Show.vue'),
  },
  {
    name: 'payments',
    path: prefix + '/payments',
    meta: {
      // title: DEFAULT_TITLE + ' | Payments',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment" */'../views/payment/List.vue'),
    children: [
      {
        name: 'payment_create',
        path: prefix + '/payments/create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Payment',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "payment" */'../views/payment/Create.vue'),
      },
    ]
  },
  {
    name: 'payment',
    path: prefix + '/payments/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Payment',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment" */'../views/payment/Show.vue'),
  },
  {
    name: 'payment_files',
    path: prefix + '/payments/:id/files',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Payment Files',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment" */'../views/payment/Files.vue'),
  },
  {
    name: 'payment_sources',
    path: prefix + '/payment-sources',
    meta: {
      // title: DEFAULT_TITLE + ' | Payment Sources',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_source" */'../views/finance/payment_source/List.vue'),
  },
  {
    name: 'payment_source_create',
    path: prefix + '/payment-sources/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Payment Source',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_source" */'../views/finance/payment_source/Show.vue'),
  },
  {
    name: 'payment_source',
    path: prefix + '/payment-sources/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Payment Source',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_source" */'../views/finance/payment_source/Show.vue'),
  },
  {
    name: 'payment_terms',
    path: prefix + '/payment-terms',
    meta: {
      // title: DEFAULT_TITLE + ' | Payment Terms',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_term" */'../views/finance/payment_term/List.vue'),
  },
  {
    name: 'payment_term_create',
    path: prefix + '/payment-terms/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Payment Term',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_term" */'../views/finance/payment_term/Show.vue'),
  },
  {
    name: 'payment_term',
    path: prefix + '/payment-terms/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Payment Term',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_term" */'../views/finance/payment_term/Show.vue'),
  },
  {
    name: 'payment_types',
    path: prefix + '/payment-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Payment Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_type" */'../views/finance/payment_type/List.vue'),
  },
  {
    name: 'payment_type_create',
    path: prefix + '/payment-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Payment Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_type" */'../views/finance/payment_type/Show.vue'),
  },
  {
    name: 'payment_type',
    path: prefix + '/payment-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Payment Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "payment_type" */'../views/finance/payment_type/Show.vue'),
  },
  {
    name: 'pending_api_calls',
    path: prefix + '/pending-api-calls',
    meta: {
      // title: DEFAULT_TITLE + ' | Pending API Calls',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "pending_api_call" */'../views/pending_api_call/List.vue'),
  },
  {
    name: 'prefill_datas',
    path: prefix + '/prefill-datas',
    meta: {
      // title: DEFAULT_TITLE + ' | Prefill Datas',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "prefill_data" */'../views/prefill_data/List.vue'),
  },
  {
    name: 'prefill_data_create',
    path: prefix + '/prefill-datas/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Prefill Data',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "prefill_data" */'../views/prefill_data/Show.vue'),
  },
  {
    name: 'prefill_data',
    path: prefix + '/prefill-datas/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Prefill Data',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "prefill_data" */'../views/prefill_data/Show.vue'),
  },
  {
    name: 'product_families',
    path: prefix + '/product-families',
    meta: {
      // title: DEFAULT_TITLE + ' | Product Families',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_family" */'../views/budget/product_family/List.vue'),
  },
  {
    name: 'product_family_create',
    path: prefix + '/product-families/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Product Family',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_family" */'../views/budget/product_family/Show.vue'),
  },
  {
    name: 'product_family',
    path: prefix + '/product-families/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Product Family',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_family" */'../views/budget/product_family/Show.vue'),
  },
  {
    name: 'product_family_types',
    path: prefix + '/product-family-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Product Family Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_family_type" */'../views/budget/product_family_type/List.vue'),
  },
  {
    name: 'product_family_type_create',
    path: prefix + '/product-family-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Product Family Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_family_type" */'../views/budget/product_family_type/Show.vue'),
  },
  {
    name: 'product_family_type',
    path: prefix + '/product-family-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Product Family Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_family_type" */'../views/budget/product_family_type/Show.vue'),
  },
  {
    name: 'product_subfamilies',
    path: prefix + '/product-subfamilies',
    meta: {
      // title: DEFAULT_TITLE + ' | Product Subfamilies',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_subfamily" */'../views/budget/product_subfamily/List.vue'),
  },
  {
    name: 'product_subfamily_create',
    path: prefix + '/product-subfamilies/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Product Subfamily',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_subfamily" */'../views/budget/product_subfamily/Show.vue'),
  },
  {
    name: 'product_subfamily',
    path: prefix + '/product-subfamilies/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Product Subfamily',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "product_subfamily" */'../views/budget/product_subfamily/Show.vue'),
  },
  {
    name: 'purchase_orders',
    path: prefix + '/purchase-orders',
    meta: {
      // title: DEFAULT_TITLE + ' | Purchase Orders',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase-order" */'../views/purchase_order/List.vue'),
    children: [
      {
        name: 'purchase_order_create',
        path: prefix + '/purchase-orders/create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Purchase Order',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "purchase-order" */'../views/purchase_order/Create.vue'),
      },
    ]
  },
  {
    name: 'purchase_order',
    path: prefix + '/purchase-orders/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Purchase Order',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase-order" */'../views/purchase_order/Show.vue'),
  },
  {
    name: 'purchase_order_send_modes',
    path: prefix + '/purchase-order-send-modes',
    meta: {
      // title: DEFAULT_TITLE + ' | Purchase Order Send Modes',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase_order_send_mode" */'../views/purchase_order/send_mode/List.vue'),
  },
  {
    name: 'purchase_order_send_mode_create',
    path: prefix + '/purchase-order-send-modes/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Purchase Order Send Mode',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase_order_send_mode" */'../views/purchase_order/send_mode/Show.vue'),
  },
  {
    name: 'purchase_order_send_mode',
    path: prefix + '/purchase-order-send-modes/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Purchase Order Send Mode',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase_order_send_mode" */'../views/purchase_order/send_mode/Show.vue'),
  },
  {
    name: 'purchase_types',
    path: prefix + '/purchase-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Purchase Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase_type" */'../views/finance/purchase_type/List.vue'),
    children: [
      {
        name: 'purchase_type_reorder',
        path: 'reorder',
        meta: {
          // title: DEFAULT_TITLE + ' | Reorder Purchase Types',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "purchase_type" */'../views/finance/purchase_type/Reorder.vue'),
      },
    ]
  },
  {
    name: 'purchase_type_create',
    path: prefix + '/purchase-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Purchase Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase_type" */'../views/finance/purchase_type/Show.vue'),
  },
  {
    name: 'purchase_type',
    path: prefix + '/purchase-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Purchase Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "purchase_type" */'../views/finance/purchase_type/Show.vue'),
  },
  {
    name: 'questions',
    path: prefix + '/questions',
    meta: {
      // title: DEFAULT_TITLE + ' | Questions',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "question" */'../views/question/List.vue'),
  },
  {
    name: 'regions',
    path: prefix + '/regions',
    meta: {
      // title: DEFAULT_TITLE + ' | Regions',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "region" */'../views/budget/region/List.vue'),
  },
  {
    name: 'region_create',
    path: prefix + '/regions/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Region',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "region" */'../views/budget/region/Show.vue'),
  },
  {
    name: 'region',
    path: prefix + '/regions/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Region',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "region" */'../views/budget/region/Show.vue'),
  },
  {
    name: 'registration',
    path: prefix + '/registration',
    meta: {
      // title: Registration',
      guest_only: true,
    },
    component: () => import(/* webpackChunkName: "registration" */'../views/registration/Form.vue'),
  },
  {
    name: 'registration_check',
    path: prefix + '/registration/check/:id',
    meta: {
      // title: Registration',
      guest_only: true,
    },
    component: () => import(/* webpackChunkName: "registration" */'../views/registration/CheckCode.vue'),
  },
  {
    name: 'registration_configure',
    path: prefix + '/registration/configure/:id',
    meta: {
      // title: Registration',
      guest_only: true,
    },
    component: () => import(/* webpackChunkName: "registration" */'../views/registration/Configure.vue'),
  },
  {
    name: 'request_types',
    path: prefix + '/request-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Request Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "request_type" */'../views/budget/request_type/List.vue'),
  },
  {
    name: 'request_type_create',
    path: prefix + '/request-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Request Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "request_type" */'../views/budget/request_type/Show.vue'),
  },
  {
    name: 'request_type',
    path: prefix + '/request-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Request Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "request_type" */'../views/budget/request_type/Show.vue'),
  },
  // {
  //   name: 'reset-password',
  //   path: prefix+'/reset-password',
  //   meta: { guest_only: true },
  //   component: () => import(/* webpackChunkName: "reset-password" */'../views/ResetPassword.vue')
  // },
  {
    name: 'roles',
    path: prefix + '/roles',
    meta: {
      // title: DEFAULT_TITLE + ' | Roles',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "role" */'../views/user/role/List.vue'),
  },
  {
    name: 'role_create',
    path: prefix + '/roles/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Role',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "role" */'../views/user/role/Show.vue'),
  },
  {
    name: 'role',
    path: prefix + '/roles/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Role',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "role" */'../views/user/role/Show.vue'),
  },
  {
    name: 'search_savings',
    path: prefix + '/search-savings',
    meta: {
      // title: DEFAULT_TITLE + ' | Search savings',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "search_saving" */'../views/user/search_saving/List.vue'),
  },
  {
    name: 'suppliers',
    path: prefix + '/suppliers',
    meta: {
      // title: DEFAULT_TITLE + ' | Suppliers',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier" */'../views/supplier/List.vue'),
    children: [
      {
        name: 'supplier_create',
        path: prefix + '/suppliers/create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Supplier',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "supplier" */'../views/supplier/Create.vue'),
      },
    ],
  },
  {
    name: 'supplier',
    path: prefix + '/suppliers/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier" */'../views/supplier/Show.vue'),
    children: [
      {
        name: 'supplier_identity',
        path: 'identity',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Identity',
          requires_auth: true,
        },
        // component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/Identity.vue'),
      },
      {
        name: 'supplier_purchase',
        path: 'purchase',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Purchase',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/Purchase.vue'),
      },
      {
        name: 'supplier_finance',
        path: 'finance',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Finance',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/Finance.vue'),
      },
      {
        name: 'supplier_contacts',
        path: 'contacts',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Contacts',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/Contacts.vue'),
      },
      {
        name: 'supplier_observations',
        path: 'observations',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Observations',
          requires_auth: true,
        },
        // component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/Observations.vue'),
      },
      {
        name: 'supplier_files_approval',
        path: 'files-approval',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Files Approval',
          requires_auth: true,
        },
        // component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/FilesApproval.vue'),
      },
      {
        name: 'supplier_files_regular',
        path: 'files-regular',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Files Regular',
          requires_auth: true,
        },
        // component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/FilesRegular.vue'),
      },
      {
        name: 'supplier_own_contracts',
        path: 'contracts',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Contracts',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/Contracts.vue'),
      },
      {
        name: 'supplier_evaluations',
        path: 'evaluations',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Evaluations',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "supplier_evaluation" */'../components/supplier/show/Evaluations.vue'),
        children: [
          {
            name: 'supplier_evaluation_create',
            path: 'create',
            meta: {
              // title: DEFAULT_TITLE + ' | Create Supplier Evaluation',
              requires_auth: true,
              modal: true,
            },
            component: () => import(/* webpackChunkName: "supplier_evaluation" */'../components/supplier/show/evaluation/Show.vue'),
          },
          {
            name: 'supplier_evaluation',
            path: ':evaluation_id',
            meta: {
              // title: DEFAULT_TITLE + ' | Supplier Evaluation',
              requires_auth: true,
              modal: true,
            },
            component: () => import(/* webpackChunkName: "supplier_evaluation" */'../components/supplier/show/evaluation/Show.vue'),
          },
        ],
      },
      {
        name: 'supplier_charts',
        path: 'charts',
        meta: {
          // title: DEFAULT_TITLE + ' | Supplier Charts',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "supplier" */'../components/supplier/show/Charts.vue'),
      },
    ]
  },
  {
    name: 'supplier_account_types',
    path: prefix + '/supplier-account-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier Account Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "role" */'../views/supplier/account_type/List.vue'),
  },
  {
    name: 'supplier_account_type_create',
    path: prefix + '/supplier-account-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier Account Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_account_type" */'../views/supplier/account_type/Show.vue'),
  },
  {
    name: 'supplier_account_type',
    path: prefix + '/supplier-account-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Account Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_account_type" */'../views/supplier/account_type/Show.vue'),
  },
  {
    name: 'supplier_approvals',
    path: prefix + '/supplier-approvals',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier Approvals',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_approval" */'../views/supplier/approval/List.vue'),
  },
  {
    name: 'supplier_approval_create',
    path: prefix + '/supplier-approvals/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier Approval',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_approval" */'../views/supplier/approval/Show.vue'),
  },
  {
    name: 'supplier_approval',
    path: prefix + '/supplier-approvals/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Approval',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_approval" */'../views/supplier/approval/Show.vue'),
  },
  {
    name: 'supplier_contact_types',
    path: prefix + '/supplier-contact-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier Contact Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contact_type" */'../views/supplier/contact_type/List.vue'),
  },
  {
    name: 'supplier_contact_type_create',
    path: prefix + '/supplier-contact-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier Contact Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contact_type" */'../views/supplier/contact_type/Show.vue'),
  },
  {
    name: 'supplier_contact_type',
    path: prefix + '/supplier-contact-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Contact Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contact_type" */'../views/supplier/contact_type/Show.vue'),
  },
  {
    name: 'supplier_contracts',
    path: prefix + '/supplier-contracts',
    meta: {
      // title: DEFAULT_TITLE + ' | Suppliers Contract',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contract" */'../views/supplier/contract/List.vue'),
  },
  {
    name: 'supplier_contract_create',
    path: prefix + '/supplier-contracts/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier Contract',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contract" */'../views/supplier/contract/Show.vue'),
  },
  {
    name: 'supplier_contract',
    path: prefix + '/supplier-contracts/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Contract',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contract" */'../views/supplier/contract/Show.vue'),
  },
  {
    name: 'supplier_contractual_statuses',
    path: prefix + '/supplier-contractual-statuses',
    meta: {
      // title: DEFAULT_TITLE + ' | Contractual Statuses',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contractual_status" */'../views/supplier/contractual_status/List.vue'),
    children: [
      {
        name: 'supplier_contractual_status_reorder',
        path: 'reorder',
        meta: {
          // title: DEFAULT_TITLE + ' | Reorder Contractual Statuses',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "supplier_contractual_status" */'../views/supplier/contractual_status/Reorder.vue'),
      },
    ]
  },
  {
    name: 'supplier_contractual_status_create',
    path: prefix + '/supplier-contractual-statuses/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Contractual Status',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contractual_status" */'../views/supplier/contractual_status/Show.vue'),
  },
  {
    name: 'supplier_contractual_status',
    path: prefix + '/supplier-contractual-statuses/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Contractual Status',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_contractual_status" */'../views/supplier/contractual_status/Show.vue'),
  },
  {
    name: 'supplier_evaluation_criteria',
    path: prefix + '/supplier-evaluation-criteria',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier Evaluation Criteria',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_evaluation_criterion" */'../views/supplier/evaluation/criterion/List.vue'),
    children: [
      {
        name: 'supplier_evaluation_criterion_reorder',
        path: 'reorder',
        meta: {
          // title: DEFAULT_TITLE + ' | Reorder Supplier Evaluation Criteria',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "supplier_evaluation_criterion" */'../views/supplier/evaluation/criterion/Reorder.vue'),
      },
    ]
  },
  {
    name: 'supplier_evaluation_criterion_create',
    path: prefix + '/supplier-evaluation-criteria/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier Evaluation Criterion',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_evaluation_criterion" */'../views/supplier/evaluation/criterion/Show.vue'),
  },
  {
    name: 'supplier_evaluation_criterion',
    path: prefix + '/supplier-evaluation-criteria/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Evaluation Criterion',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_evaluation_criterion" */'../views/supplier/evaluation/criterion/Show.vue'),
  },
  {
    name: 'supplier_file_categories',
    path: prefix + '/supplier-file-categories',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier File Categories',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_file_category" */'../views/supplier/file_category/List.vue'),
  },
  {
    name: 'supplier_file_category_create',
    path: prefix + '/supplier-file-categories/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier File Category',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_file_category" */'../views/supplier/file_category/Show.vue'),
  },
  {
    name: 'supplier_file_category',
    path: prefix + '/supplier-file-categories/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier File Category',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_file_category" */'../views/supplier/file_category/Show.vue'),
  },
  {
    name: 'supplier_file_types',
    path: prefix + '/supplier-file-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier File Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_file_type" */'../views/supplier/file_type/List.vue'),
  },
  {
    name: 'supplier_file_type_create',
    path: prefix + '/supplier-file-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier File Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_file_type" */'../views/supplier/file_type/Show.vue'),
  },
  {
    name: 'supplier_file_type',
    path: prefix + '/supplier-file-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier File Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_file_type" */'../views/supplier/file_type/Show.vue'),
  },
  {
    name: 'supplier_groups',
    path: prefix + '/supplier-groups',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier Groups',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_group" */'../views/supplier/group/List.vue'),
  },
  {
    name: 'supplier_group_create',
    path: prefix + '/supplier-groups/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Supplier Group',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_group" */'../views/supplier/group/Show.vue'),
  },
  {
    name: 'supplier_group',
    path: prefix + '/supplier-groups/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Group',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier_group" */'../views/supplier/group/Show.vue'),
  },
  {
    name: 'supplier_invoices',
    path: prefix + '/supplier-invoices',
    meta: {
      // title: DEFAULT_TITLE + ' | Supplier Invoices',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier-invoice" */'../views/supplier/invoice/List.vue'),
  },
  {
    name: 'supplier_invoices_pending',
    path: prefix + '/supplier-invoices/pending',
    meta: {
      // title: DEFAULT_TITLE + ' | Pending Supplier Invoices',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "pending-supplier-invoice" */'../views/supplier/invoice/ListPending.vue'),
  },
  {
    name: 'supplier_invoice_create',
    path: prefix + '/supplier-invoices/create-pending',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Pending Supplier Invoice',
      requires_auth: true,
      modal: true,
    },
    component: () => import(/* webpackChunkName: "supplier-invoice" */'../views/supplier/invoice/ShowPreview.vue'),
  },
  {
    name: 'supplier_invoice_upload_multiple',
    path: prefix + '/supplier-invoices/upload-multiple',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Multiple Pending Supplier Invoice',
      requires_auth: true,
      modal: true,
    },
    component: () => import(/* webpackChunkName: "supplier-invoice" */'../views/supplier/invoice/UploadMultiple.vue'),
  },
  {
    name: 'supplier_invoice',
    path: prefix + '/supplier-invoices/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Invoice',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "supplier-invoice" */'../views/supplier/invoice/Show.vue'),
  },
  {
    name: 'supplier_invoice_show_preview',
    path: prefix + '/supplier-invoices/:id/show-preview',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Supplier Invoice Preview',
      requires_auth: true,
      modal: true,
    },
    component: () => import(/* webpackChunkName: "supplier-invoice" */'../views/supplier/invoice/ShowPreview.vue'),
  },
  {
    name: 'supplier_qualifications',
    path: prefix + '/supplier-qualifications',
    meta: {
      // title: DEFAULT_TITLE + ' | Qualifications',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "qualification" */'../views/supplier/qualification/List.vue'),
    children: [
      {
        name: 'supplier_qualification_reorder',
        path: 'reorder',
        meta: {
          // title: DEFAULT_TITLE + ' | Reorder Qualifications',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "qualification" */'../views/supplier/qualification/Reorder.vue'),
      },
    ]
  },
  {
    name: 'supplier_qualification_create',
    path: prefix + '/supplier-qualifications/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Qualification',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "qualification" */'../views/supplier/qualification/Show.vue'),
  },
  {
    name: 'supplier_qualification',
    path: prefix + '/supplier-qualifications/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Qualification',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "qualification" */'../views/supplier/qualification/Show.vue'),
  },
  {
    name: 'tags',
    path: prefix + '/tags',
    meta: {
      // title: DEFAULT_TITLE + ' | Tags',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "tag" */'../views/tag/List.vue'),
  },
  {
    name: 'tag_create',
    path: prefix + '/tags/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Tag',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "tag" */'../views/tag/Show.vue'),
  },
  {
    name: 'tag',
    path: prefix + '/tags/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Tag',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "tag" */'../views/tag/Show.vue'),
  },
  {
    name: 'units',
    path: prefix + '/units',
    meta: {
      // title: DEFAULT_TITLE + ' | Units',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "unit" */'../views/catalog/unit/List.vue'),
  },
  {
    name: 'unit_create',
    path: prefix + '/units/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Unit',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "unit" */'../views/catalog/unit/Show.vue'),
  },
  {
    name: 'unit',
    path: prefix + '/units/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Unit',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "unit" */'../views/catalog/unit/Show.vue'),
  },
  {
    name: 'users',
    path: prefix + '/users',
    meta: {
      // title: DEFAULT_TITLE + ' | Users',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user" */'../views/user/List.vue'),
    children: [
      {
        name: 'user_create',
        path: prefix + '/users/create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create User',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "user" */'../views/user/Create.vue'),
      },
    ],
  },
  {
    name: 'user',
    path: prefix + '/users/:id',
    redirect: { name: 'user_profile' },
    meta: {
      // title: DEFAULT_TITLE + ' | Show User',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user" */'../views/user/Show.vue'),
    children: [
      {
        name: 'user_profile',
        path: 'profile',
        meta: {
          // title: DEFAULT_TITLE + ' | User Profile',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "user" */'../components/user/show/Profile.vue'),
      },
      {
        name: 'user_permissions',
        path: 'permissions',
        meta: {
          // title: DEFAULT_TITLE + ' | User Permissions',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "user" */'../components/user/show/Permissions.vue'),
      },
      {
        name: 'user_notifications',
        path: 'notifications',
        meta: {
          // title: DEFAULT_TITLE + ' | User Notifications',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "user" */'../components/user/show/Notifications.vue'),
      },
      {
        name: 'user_preferences',
        path: 'preferences',
        meta: {
          // title: DEFAULT_TITLE + ' | User Preferences',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "user" */'../components/user/show/Preferences.vue'),
      },
      {
        name: 'user_password',
        path: 'password',
        meta: {
          // title: DEFAULT_TITLE + ' | User Password',
          requires_auth: true,
        },
        component: () => import(/* webpackChunkName: "user" */'../components/user/show/Password.vue'),
      },
    ]
  },
  {
    name: 'user_assignments',
    path: prefix + '/user-assignments',
    meta: {
      // title: DEFAULT_TITLE + ' | User Assignments',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user_assignment" */'../views/user/assignment/List.vue'),
  },
  {
    name: 'user_assignment_create',
    path: prefix + '/user-assignments/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create User Assignment',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user_assignment" */'../views/user/assignment/Show.vue'),
  },
  {
    name: 'user_assignment',
    path: prefix + '/user-assignments/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show User Assignment',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user_assignment" */'../views/user/assignment/Show.vue'),
  },
  {
    name: 'user_groups',
    path: prefix + '/user-groups',
    meta: {
      // title: DEFAULT_TITLE + ' | User Groups',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user_group" */'../views/user/group/List.vue'),
  },
  {
    name: 'user_group_create',
    path: prefix + '/user-groups/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create User Group',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user_group" */'../views/user/group/Show.vue'),
  },
  {
    name: 'user_group',
    path: prefix + '/user-groups/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show User Group',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "user_group" */'../views/user/group/Show.vue'),
  },
  {
    name: 'validations',
    path: prefix + '/validations',
    meta: {
      // title: DEFAULT_TITLE + ' | Validations',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "validation_process" */'../views/validation/List.vue'),
  },
  {
    name: 'validation_processes',
    path: prefix + '/validation-processes',
    meta: {
      // title: DEFAULT_TITLE + ' | Validation Processes',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "validation_process" */'../views/validation_process/List.vue'),
  },
  {
    name: 'validation_process',
    path: prefix + '/validation-processes/:id/:criterion?',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Validation Process',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "validation_process" */'../views/validation_process/Show.vue'),
  },
  {
    name: 'vat_types',
    path: prefix + '/vat-types',
    meta: {
      // title: DEFAULT_TITLE + ' | Vat Types',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "vat_type" */'../views/finance/vat_type/List.vue'),
  },
  {
    name: 'vat_type_create',
    path: prefix + '/vat-types/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Vat Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "vat_type" */'../views/finance/vat_type/Show.vue'),
  },
  {
    name: 'vat_type',
    path: prefix + '/vat-types/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Vat Type',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "vat_type" */'../views/finance/vat_type/Show.vue'),
  },
  {
    name: 'warehouses',
    path: prefix + '/warehouses',
    meta: {
      // title: DEFAULT_TITLE + ' | Warehouses',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "warehouse" */'../views/supplier/warehouse/List.vue'),
  },
  {
    name: 'warehouse_create',
    path: prefix + '/warehouses/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Warehouse',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "warehouse" */'../views/supplier/warehouse/Show.vue'),
  },
  {
    name: 'warehouse',
    path: prefix + '/warehouses/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Warehouse',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "warehouse" */'../views/supplier/warehouse/Show.vue'),
  },
  {
    name: 'warehouse_articles',
    path: prefix + '/warehouses/:warehouse_id/articles',
    meta: {
      // title: DEFAULT_TITLE + ' | Warehouse Articles',
      requires_auth: true
    },
    component: () => import(/* webpackChunkName: "warehouse_article" */'../views/catalog/warehouse_article/List.vue'),
    children: [
      {
        name: 'warehouse_article_create',
        path: 'create',
        meta: {
          // title: DEFAULT_TITLE + ' | Create Warehouse Article',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "warehouse_article" */'../views/catalog/warehouse_article/Create.vue'),
      },
      {
        name: 'warehouse_article',
        path: ':id',
        meta: {
          // title: DEFAULT_TITLE + ' | Show Warehouse Article',
          requires_auth: true,
          modal: true,
        },
        component: () => import(/* webpackChunkName: "warehouse_article" */'../views/catalog/warehouse_article/Show.vue'),
      },
    ],
  },
  // admin
  {
    name: 'admin',
    path: prefix + '/admin',
    meta: {
      // title: DEFAULT_TITLE + ' | Admin',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "admin" */'../views/admin/Index.vue'),
  },
  // config
  {
    name: 'config',
    path: prefix + '/config',
    meta: {
      // title: DEFAULT_TITLE + ' | Config',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Index.vue'),
  },
  {
    name: 'config_allocations',
    path: prefix + '/config/allocations',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Allocations',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Allocations.vue'),
  },
  {
    name: 'config_api',
    path: prefix + '/config/api',
    meta: {
      // title: DEFAULT_TITLE + ' | Config API',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Api.vue'),
  },
  {
    name: 'config_bank_accounts',
    path: prefix + '/config/bank-accounts',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Bank Accounts',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/BankAccounts.vue'),
  },
  {
    name: 'config_budgets',
    path: prefix + '/config/budgets',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Budgets',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Budgets.vue'),
  },
  {
    name: 'config_catalog',
    path: prefix + '/config/catalog',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Catalog',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Catalog.vue'),
  },
  {
    name: 'config_dashboard',
    path: prefix + '/config/dashboard',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Dashboard',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Dashboard.vue'),
  },
  {
    name: 'config_deliveries',
    path: prefix + '/config/deliveries',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Deliveries',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Deliveries.vue'),
  },
  {
    name: 'config_external_charges',
    path: prefix + '/config/external-charges',
    meta: {
      // title: DEFAULT_TITLE + ' | Config External Charges',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/ExternalCharges.vue'),
  },
  {
    name: 'config_mirroring_database',
    path: prefix + '/config/mirroring-database',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Mirroring Database',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/MirroringDatabase.vue'),
  },
  {
    name: 'config_model_fields',
    path: prefix + '/config/model-fields',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Models Fields',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/ModelFields.vue'),
  },
  {
    name: 'config_models',
    path: prefix + '/config/models',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Models',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Models.vue'),
  },
  {
    name: 'config_modules',
    path: prefix + '/config/modules',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Modules',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Modules.vue'),
  },
  {
    name: 'config_needs',
    path: prefix + '/config/needs',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Needs',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Needs.vue'),
  },
  {
    name: 'config_notifications',
    path: prefix + '/config/notifications',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Notifications',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Notifications.vue'),
  },
  {
    name: 'config_payments',
    path: prefix + '/config/payments',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Payments',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Payments.vue'),
  },
  {
    name: 'config_purchase_orders',
    path: prefix + '/config/purchase-orders',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Purchase Orders',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/PurchaseOrders.vue'),
  },
  {
    name: 'config_suppliers',
    path: prefix + '/config/suppliers',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Suppliers',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Suppliers.vue'),
  },
  {
    name: 'config_supplier_contracts',
    path: prefix + '/config/supplier-contracts',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Supplier Contracts',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/SupplierContracts.vue'),
  },
  {
    name: 'config_supplier_invoices',
    path: prefix + '/config/supplier-invoices',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Supplier Invoices',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/SupplierInvoices.vue'),
  },
  {
    name: 'config_tenant',
    path: prefix + '/config/tenant',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Tenant',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Tenant.vue'),
  },
  {
    name: 'config_users',
    path: prefix + '/config/users',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Users',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/Users.vue'),
  },
  {
    name: 'config_validation_processes',
    path: prefix + '/config/validation-processes',
    meta: {
      // title: DEFAULT_TITLE + ' | Config Validation Processes',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/ValidationProcesses.vue'),
  },
  {
    name: 'custom_fields',
    path: prefix + '/custom-fields',
    meta: {
      // title: DEFAULT_TITLE + ' | Custom Fields',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "config" */'../views/config/custom_field/List.vue'),
  },
  // maintenance
  {
    name: 'maintenance',
    path: prefix + '/maintenance',
    meta: {
      // title: DEFAULT_TITLE + ' | Maintenance',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/Index.vue'),
  },
  {
    name: 'maintenance_push_notification',
    path: prefix + '/maintenance/push-notification',
    meta: {
      // title: DEFAULT_TITLE + ' | Push Notification',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/PushNotification.vue'),
  },
  {
    name: 'maintenance_tests',
    path: prefix + '/maintenance/tests',
    meta: {
      // title: DEFAULT_TITLE + ' | Tests',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/Tests.vue'),
  },
  {
    name: 'data_accuracy',
    path: prefix + '/maintenance/data-accuracy',
    meta: {
      // title: DEFAULT_TITLE + ' | Data Accuracy',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/DataAccuracy.vue'),
  },
  {
    name: 'maintenance_disable_tenant',
    path: prefix + '/maintenance/disable-tenant',
    meta: {
      // title: DEFAULT_TITLE + ' | Disable Tenant',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/DisableTenant.vue'),
  },
  {
    name: 'query_builder',
    path: prefix + '/admin/sql-query',
    meta: {
      // title: DEFAULT_TITLE + ' | Query builder',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/SqlQuery.vue'),
  },
  {
    name: 'reset_demo_data',
    path: prefix + '/maintenance/reset-demo-data',
    meta: {
      // title: DEFAULT_TITLE + ' | Reset Demo Data',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */'../views/maintenance/ResetDemoData.vue'),
  },
  // errors
  {
    path: "*",
    component: () => import(/* webpackChunkName: "errors" */'../views/errors/ErrorPage.vue'),
  },
  // central
  {
    name: 'tenants',
    path: central_prefix + '/tenants',
    meta: {
      // title: DEFAULT_TITLE + ' | Tenants',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "tenant" */'../views/central/tenant/List.vue'),
  },
  {
    name: 'tenant_create',
    path: central_prefix + '/tenants/create',
    meta: {
      // title: DEFAULT_TITLE + ' | Create Tenant',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "tenant" */'../views/central/tenant/Create.vue'),
  },
  {
    name: 'tenant',
    path: central_prefix + '/tenants/:id',
    meta: {
      // title: DEFAULT_TITLE + ' | Show Tenant',
      requires_auth: true,
    },
    component: () => import(/* webpackChunkName: "tenant" */'../views/central/tenant/Show.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

function isLoggedIn() {
  return localStorage.getItem("auth");
}

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE + ' | Gestion des Achats';
  store.commit("setErrorInfos", null, { root: true });
  store.commit("setLastRoute", { name: from.name, params: from.params });

  // /* Change the Title tag */
  // // This goes through the matched routes from last to first, finding the closest route with a title.
  // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // // Find the nearest route element with meta tags.
  // const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // // If a route with a title was found, set the document (page) title to that value.
  // if (nearestWithTitle) {
  //   document.title = nearestWithTitle.meta.title;
  // } else if (previousNearestWithMeta) {
  //   document.title = previousNearestWithMeta.meta.title;
  // }

  // // Remove any stale meta tags from the document using the key attribute we set below.
  // Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // // Skip rendering meta tags if there are none.
  // if (!nearestWithMeta) return next();

  // // Turn the meta tag definitions into actual elements in the head.
  // nearestWithMeta.meta.metaTags.map(tagDef => {
  //   const tag = document.createElement('meta');

  //   Object.keys(tagDef).forEach(key => {
  //     tag.setAttribute(key, tagDef[key]);
  //   });

  //   // We use this to track which meta tags we create so we don't interfere with other ones.
  //   tag.setAttribute('data-vue-router-controlled', '');

  //   return tag;
  // })
  //   // Add the meta tags to the document head.
  //   .forEach(tag => document.head.appendChild(tag));

  // /* Authentication test */
  if (to.matched.some(record => record.meta.requires_auth)) {
    if (!isLoggedIn()) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest_only)) {
    if (isLoggedIn()) {
      next({
        path: prefix + "/",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next() // make sure to always call next()!
  }
})

// router.afterEach((to) => {
//   Vue.nextTick(() => {
//     document.title = to.meta.title || DEFAULT_TITLE;
//   });
// });

export default router
