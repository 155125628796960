import axios from "../../services/api";
import router from "../../router"

let default_root = '/bwapi/';
let root = default_root + 'users';
let config_name = 'user';

export default {
    search(query, callback, failure_callback) {
        axios.get(root + "/search?search=" + encodeURIComponent(query))
            .then((response) => {
                response.data.initial_search = query;
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    searchRecipient(query, callback, failure_callback) {
        axios.get(root + "/search?recipient&search=" + encodeURIComponent(query))
            .then((response) => {
                response.data.initial_search = query;
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getLoginInfos(callback) {
        axios.get(root + "/login-infos")
            .then((response) => {
                callback(response.data);
            });
    },
    login(form) {
        // await axios.get("/sanctum/csrf-cookie");
        return axios.post("/login", {
            login: form.login,
            password: form.password,
            remember: form.remember,
            redirect: form.redirect,
        });
    },
    supplierLoginRequest(form, callback, failure_callback) {
        return axios.post("/supplier-login-request", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    loginFromToken(form) {
        return axios.post(default_root + "login-from-token", form);
    },
    logout(callback) {
        axios.post("/logout")
            .then((response) => {
                callback(response.data);
            });
    },
    testSocket(item_id, callback) {
        axios.get(default_root + "test-socket/" + item_id)
            .then((response) => {
                callback(response.data);
            });
    },
    getCurrentUser(callback) {
        axios.get(root + "/current")
            .then((response) => {
                localStorage.setItem("auth", "true");
                callback(response.data.data);
            })
            .catch(() => {
                localStorage.removeItem("auth");
                if (router.history.current.meta && router.history.current.meta.requires_auth) {
                    router.push({ name: "login" });
                }
            });
    },
    getIndex(callback, failure_callback) {
        return axios.get(root + "/index" + window.location.search)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    refreshList(query, callback) {
        return axios.get(root + "?" + query)
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getFilterList(field, search, query, callback) {
        let complement = query ? ('&' + query) : '';
        return axios.get(root + "/filter-list/" + field +
            "?search=" +
            encodeURIComponent(search) + complement)
            .then((response) => {
                response.data.initial_search = search;
                callback(response.data);
                return response.data
            });
    },
    getBulkActionInfo(action, callback, failure_callback) {
        return axios.get(root + "/bulk-action-info/" + action)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    doBulkAction(data, callback, failure_callback) {
        return axios.post(root + "/do-bulk-action", data)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getContextualMenu(item_id, callback) {
        return axios.get(root + "/" + item_id + "/contextual-menu")
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getItem(item_id, callback, failure_callback) {
        // return axios.get(root + "/" + item_id + "/profile")
        //     .then((response) => {
        //         callback(response.data);
        //         return response.data
        //     });
        return axios.get(root + "/" + item_id + "/profile")
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getCreateInfos(callback) {
        return axios.get(root + "/create")
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    createItem(form, callback, failure_callback) {
        axios.post(root, form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    saveUserProfile(item_id, form, callback, failure_callback) {
        axios.post(root + "/" + item_id + "/profile", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    saveUserProfileSignature(item_id, file, callback, failure_callback) {
        let form_data = new FormData()
        form_data.append('signature', file)

        axios.post(root + "/" + item_id + "/profile-signature", form_data)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getUserNotifications(item_id, callback) {
        axios.get(root + "/" + item_id + "/notifications")
            .then((response) => {
                callback(response.data);
            });
    },
    saveUserNotifications(item_id, form, callback, failure_callback) {
        axios.post(root + "/" + item_id + "/notifications", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getUserPreferences(item_id, callback) {
        axios.get(root + "/" + item_id + "/preferences")
            .then((response) => {
                callback(response.data);
            });
    },
    saveUserPreferences(item_id, form, callback, failure_callback) {
        axios.post(root + "/" + item_id + "/preferences", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getUserPermissions(item_id, callback) {
        axios.get(root + "/" + item_id + "/permissions")
            .then((response) => {
                callback(response.data);
            });
    },
    saveUserPermissions(item_id, form, callback, failure_callback) {
        axios.post(root + "/" + item_id + "/permissions", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getPermissionsDetails(item_id, type, callback, failure_callback) {
        let complement = type ? '?type=' + type : '';
        axios.get(root + "/" + item_id + "/permissions-details" + complement)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getUserPasswordInfos(item_id, callback) {
        axios.get(root + "/" + item_id + "/password")
            .then((response) => {
                callback(response.data);
            });
    },
    savePassword(item_id, form, callback, failure_callback) {
        axios.post(root + "/" + item_id + "/password", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getTracesInfos(item_id, callback) {
        axios.get(default_root + "traces/" + config_name + "/" + item_id)
            .then((response) => {
                callback(response.data);
            })
    },
    searchBuyers(query, callback, failure_callback) {
        axios.get(root + "/buyers?search=" + encodeURIComponent(query))
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    sendDoubleAuthenticationCode(callback, failure_callback) {
        axios.post(root + "/send-double-authentication-code")
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    confirmDoubleAuthenticationCode(code, callback, failure_callback) {
        axios.post(root + "/confirm-double-authentication-code", { code: code })
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                console.warn('response', response)
                failure_callback(response.response);
            });
    },
}