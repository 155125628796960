import axios from "../../services/api";

let default_root = '/bwapi/';
let root = default_root + 'suppliers';
let config_name = 'supplier';

export default {
    search(query, callback, failure_callback) {
        axios.get(root + "/search?search=" + encodeURIComponent(query))
            .then((response) => {
                response.data.initial_search = query;
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    searchOnlySuppliers(query, callback, failure_callback) {
        axios.get(root + "/search?only_suppliers&search=" + encodeURIComponent(query))
            .then((response) => {
                response.data.initial_search = query;
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    searchOnlyPunchout(query, callback, failure_callback) {
        axios.get(root + "/search?only_punchout&search=" + encodeURIComponent(query))
            .then((response) => {
                response.data.initial_search = query;
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getIndex(callback, failure_callback) {
        return axios.get(root + "/index" + window.location.search)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    refreshList(query, callback) {
        return axios.get(root + "?" + query)
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getFilterList(field, search, query, callback) {
        let complement = query ? ('&' + query) : '';
        return axios.get(root + "/filter-list/" + field +
            "?search=" +
            encodeURIComponent(search) + complement)
            .then((response) => {
                response.data.initial_search = search;
                callback(response.data);
                return response.data
            });
    },
    saveResearch(form, callback, failure_callback) {
        return axios.post(root + "/save-research", form)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getBulkActionInfo(action, callback, failure_callback) {
        return axios.get(root + "/bulk-action-info/" + action)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    doBulkAction(data, callback, failure_callback) {
        return axios.post(root + "/do-bulk-action", data)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getContextualMenu(item_id, callback) {
        return axios.get(root + "/" + item_id + "/contextual-menu")
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getCreateInfos(callback) {
        return axios.get(root + "/create")
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    createItem(form, callback, failure_callback) {
        axios.post(root, form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    searchTag(query, callback, failure_callback) {
        axios.get(root + "/search-tag?search=" + encodeURIComponent(query))
            .then((response) => {
                response.data.initial_search = query;
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getTagInfos(item_id, callback) {
        axios.get(root + "/" + item_id + "/tag")
            .then((response) => {
                callback(response.data);
            })
    },
    tag(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/tag", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getHeader(item_id, callback, failure_callback) {
        return axios.get(root + "/" + item_id + "/header")
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    launchValidation(item_id, params = {}, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/launch-validation", params)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getTabContacts(item_id, callback) {
        axios.get(root + "/" + item_id + "/contacts")
            .then((response) => {
                callback(response.data);
            });
    },
    getTabContracts(item_id, callback) {
        axios.get(root + "/" + item_id + "/contracts")
            .then((response) => {
                callback(response.data);
            });
    },
    giveAccess(item_id, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/give-access")
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    removeAccess(item_id, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/remove-access")
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getTabEvaluations(item_id, callback) {
        axios.get(root + "/" + item_id + "/evaluations")
            .then((response) => {
                callback(response.data);
            });
    },
    getTabFinance(item_id, callback) {
        axios.get(root + "/" + item_id + "/finance")
            .then((response) => {
                callback(response.data);
            });
    },
    getFinance(item_id, callback) {
        axios.get(root + "/" + item_id + "/finance/finance")
            .then((response) => {
                callback(response.data);
            });
    },
    saveFinance(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/finance/finance", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getTabPurchase(item_id, callback) {
        axios.get(root + "/" + item_id + "/purchase")
            .then((response) => {
                callback(response.data);
            });
    },
    getClassification(item_id, callback) {
        axios.get(root + "/" + item_id + "/purchase/classification")
            .then((response) => {
                callback(response.data);
            });
    },
    saveClassification(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/purchase/classification", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getPurchaseInformation(item_id, callback) {
        axios.get(root + "/" + item_id + "/purchase/purchase-information")
            .then((response) => {
                callback(response.data);
            });
    },
    savePurchaseInformation(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/purchase/purchase-information", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getSurveillance(item_id, callback) {
        axios.get(root + "/" + item_id + "/purchase/surveillance")
            .then((response) => {
                callback(response.data);
            });
    },
    saveSurveillance(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/purchase/surveillance", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getPunchoutSettings(item_id, callback) {
        axios.get(root + "/" + item_id + "/purchase/punchout-settings")
            .then((response) => {
                callback(response.data);
            });
    },
    savePunchoutSettings(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/purchase/punchout-settings", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getOcr(item_id, callback) {
        axios.get(root + "/" + item_id + "/purchase/ocr")
            .then((response) => {
                callback(response.data);
            });
    },
    saveOcr(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/purchase/ocr", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    ocrAddToAutoAllocations(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/ocr-add-to-auto-allocations", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getCharts(item_id, callback) {
        axios.get(root + "/" + item_id + "/charts")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartAdditionalRating(item_id, callback) {
        return axios.get(root + "/" + item_id + "/charts/additional_rating")
            .then((response) => {
                callback(response.data);
            });
    },
    saveAdditionalRating(item_id, form) {
        return axios.post(root + "/" + item_id + "/charts/additional_rating", form)
    },
    getChartAnnualRating(item_id, callback) {
        axios.get(root + "/" + item_id + "/charts/annual_rating")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartCertification(item_id, callback) {
        return axios.get(root + "/" + item_id + "/charts/certification")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartPerformance(item_id, callback) {
        axios.get(root + "/" + item_id + "/charts/performance")
            .then((response) => {
                callback(response.data);
            });
    },
    getChartSecurity(item_id, callback) {
        return axios.get(root + "/" + item_id + "/charts/security")
            .then((response) => {
                callback(response.data);
            });
    },
    saveSecurity(item_id, form) {
        return axios.post(root + "/" + item_id + "/charts/security", form)
    },
    getChartsTurnover(item_id, callback) {
        return axios.get(root + "/" + item_id + "/charts/turnover")
            .then((response) => {
                callback(response.data);
            });
    },
    saveTurnover(item_id, form) {
        return axios.post(root + "/" + item_id + "/charts/turnover", form)
    },
    getForceStatusInfos(item_id, callback) {
        axios.get(root + "/" + item_id + "/force-status")
            .then((response) => {
                callback(response.data);
            })
    },
    forceStatus(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/force-status", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getBackToDraftInfos(item_id, callback) {
        axios.get(root + "/" + item_id + "/back-to-draft")
            .then((response) => {
                callback(response.data);
            })
    },
    backToDraft(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/back-to-draft", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    disable(item_id, form, callback, failure_callback) {
        axios.post(root + "/" + item_id + "/disable")
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getDeleteInfos(item_id, callback) {
        axios.get(root + "/" + item_id + "/delete")
            .then((response) => {
                callback(response.data);
            })
    },
    delete(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/delete", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getTracesInfos(item_id, callback) {
        axios.get(default_root + "traces/" + config_name + "/" + item_id)
            .then((response) => {
                callback(response.data);
            })
    },
    inviteForEvaluation(item_id, form, callback, failure_callback) {
        axios.post(root + "/" + item_id + "/evaluations/invite", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getEvaluation(item_id, evaluation_id, callback, failure_callback) {
        if (evaluation_id) {
            axios.get(root + "/" + item_id + "/evaluations/" + evaluation_id)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        } else {
            axios.get(root + "/" + item_id + "/evaluations/create")
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        }
    },
    saveEvaluation(item_id, evaluation_id, form, callback, failure_callback) {
        if (evaluation_id) {
            axios.put(root + "/" + item_id + "/evaluations/" + evaluation_id, form)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        } else {
            axios.post(root + "/" + item_id + "/evaluations", form)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        }
    },
    deleteEvaluation(item_id, form, callback, failure_callback) {
        axios.delete(root + "/" + form.supplier_id + "/evaluations/" + item_id)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
}