<template>
  <b-modal
    id="double-authentication-modal"
    :title-html="$t('double_authentication.title')"
    hide-footer
    @show="modalOpened"
    @hide="modalClosed"
  >
    <div :style="colors ? colors.classes : null">
      <b-form @submit.prevent="confirmCode">
        <b-form-group
          label-for="double_authentication_code"
          :label="$t('double_authentication.info.fill_code', { email: current_user ? (current_user.real_user ? current_user.real_user.obfuscated_email : current_user.obfuscated_email) : null })"
        >
          <b-form-input
            id="double_authentication_code"
            v-model="code"
            :state="state('code')"
          />
          <b-form-invalid-feedback v-if="errors.code">{{ errors.code[0] }}</b-form-invalid-feedback>
        </b-form-group>

        <b-link
          variant="primary"
          :disabled="loading"
          @click="sendRequest"
        >
          {{ $t('double_authentication.action.resend_email') }}
        </b-link>

        <b-button
          type="submit"
          variant="primary"
          class="float-right"
          :disabled="loading"
        >
          <font-awesome-icon
            v-if="icons"
            :icon="['fal', loading ? icons.info.loading : icons.action.confirm]"
            :spin="loading"
          />
          {{ $t('global.action.confirm') }}
        </b-button>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import api from "../../api/user/user";
import { mapState } from "vuex";

export default {
  name: "DoubleAuthentication",
  mounted() {
    if (!this.$root._events["open-double-authentication-modal"]) {
      this.$root.$on("open-double-authentication-modal", (source) => {
        this.source = source;
        this.$bvModal.show("double-authentication-modal");
      });
    }
  },
  data() {
    return {
      source: null, // the source of the call
      loading: false,
      code: null,
      errors: {},
    };
  },
  methods: {
    state(field) {
      if (this.errors !== undefined && this.errors[field]) {
        return false;
      }
    },
    sendRequest() {
      this.loading = true;

      api.sendDoubleAuthenticationCode(
        (response) => {
          this.loading = false;

          // this.toast_success ne fonctionne pas...
          this.$root.$bvToast.toast(this.$t("global.confirm.mail_sent"), {
            variant: "success",
          });
        },
        (failure) => {
          this.loading = false;
          this.catch_failure(failure);
        }
      );
    },
    confirmCode() {
      this.loading = true;

      api.confirmDoubleAuthenticationCode(
        this.code,
        (response) => {
          this.loading = false;
          this.errors = {};

          this.$root.$emit("double-authentication-success", this.source);
          this.$root.$emit("refresh-current-user");

          this.$bvModal.hide("double-authentication-modal");
        },
        (failure) => {
          this.loading = false;

          if (failure.data.errors) {
            this.errors = failure.data.errors;

            if (failure.data.errors.attempts_exceeded) {
              // this.toast_error ne fonctionne pas...
              this.$root.$bvToast.toast(failure.data.errors.attempts_exceeded, {
                variant: "danger",
              });

              this.$bvModal.hide("double-authentication-modal");

              this.errors = {};
            }
          }
        }
      );
    },
    modalOpened() {
      // this.toast_success ne fonctionne pas...
      this.$root.$bvToast.toast(this.$t("global.confirm.mail_sent"), {
        variant: "success",
      });
      this.$root.$emit("refresh-current-user");
    },
    modalClosed() {
      this.$root.$emit("double-authentication-modal-closed");

      this.source = null;
      this.loading = false;
      this.code = null;
      this.errors = {};
    },
  },
  computed: {
    ...mapState({
      colors: (state) => state.layout.colors,
      current_user: (state) => state.user.current_user,
      icons: (state) => state.layout.icons,
    }),
  },
};
</script>
